import React, { Component } from 'react';
import axios from 'axios';
import { Api } from 'shared/api';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import ClassNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Modal from 'components/modal/index.jsx';
import AssetHelper, { ImageVariants } from 'AssetHelper';
import FacebookIcon from 'static/home/made-by-me/ic_facebook.svg';

import shareOnFacebook from 'shared/shareOnFacebook';

const styles = theme => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '& .modal__body': {
      padding: 24,
      width: 600,
      [theme.breakpoints.down('md')] : {
        width: '100%',
      },
    },
    '& .modal__window': {
      [theme.breakpoints.down('md')] : {
        maxHeight: 'none',
        width: '100%',
        height: '100%',
      },
    },
    '& .modal__close': {
      width: '25px !important',
      height: 20,
    },
    '& .jss221': {
      minHeight: 'none',
    }
  },
  title: {
    fontSize: 27,
    lineHeight: 1,
    fontWeight: 600,
    textAlign: 'left',
    maxWidth: 530,
  },
  mediaRow: {
    backgroundColor: theme.palette.accentBlue,
    height: 40,
    width: 210,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 7,
    marginBottom: 16,
    marginTop: 24,
    color: 'white',
    cursor: 'pointer',
  },
  mediaIcon: {
    height: 32,
    fill: 'none',
    cursor: 'pointer',
    marginRight: 8,
  },
  hr: {
    width: '100%',
    height: 2,
    backgroundColor: 'gray',
    marginBottom: 6,
  },
  info1: {
    textAlign: 'left',
  },
  copyButton: {
    color: theme.palette.mediumBlue,
    fontSize: 13,
    padding: 5,
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'none',
    height: 28,
    width: 160,
    border: 'none',
    borderRadius: 5,
    minHeight: 'none !important',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,

    },
    '& .jss221': {
      minHeight: 'none',
    }
  },
  info2: {
    textAlign: 'left',
    marginTop: 5,
  },
  html: {
    width: '100%',
    height: 144,
    marginTop: 0,
  },
  sizes: {
    width: 70,
    marginLeft: 10,
    marginTop: 0,
    marginBottom: 0,
  },
  sizesContaier: {
    flexWrap: 'nowrap',
  },
  checkboxContainer: {
    minWidth: 223,
  },
  checked: {},
  checkbox: {
    padding: 0,
    marginRight: 5,
  },
  overlay: {
    flexDirection: 'column',
  },
  embedLink: {
    padding: 10,
    width: '100%',
    border: '1px solid #006783',
    borderRadius: 7,
    textAlign: 'left',
    margin: '9px 0 9px 0',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'brandon, sans-serif',
    lineHeight: '1.46429em',
  },
  textArea: {
    resize: 'none',
    height: 144,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'brandon, sans-serif',
    lineHeight: '1.46429em',
  },
  disabled: {
    backgroundColor: 'whitesmoke',
    '&:hover': {
      backgroundColor: 'whitesmoke',
    }
  },
  shareFb: {
    color: 'white',
  },
  emailPreview: {
    padding: 15,
    backgroundColor: 'whitesmoke',
    marginTop: 10,
    marginBottom: 24,
  },
  previewTitle: {
    textAlign: 'left',
    color: theme.palette.mediumBlue,
    fontSize: 20,
    marginTop: 10,
    fontWeight: 600,
  },
  previewInfo: {
    textAlign: 'left',
  },
  presentationBackground: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: 240,
  }
});

function validateEmail(value) {
  return value && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
}

class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      comment: '',
    };
    this.handleSendInvite = this.handleSendInvite.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    const { onClose } = this.props;
    onClose();
    this.setState({ email: '', comment: '' });
  }
  handleSendInvite(hash) {
    const {email, comment} = this.state;
    const { t } = this.props;
    axios.post(
      Api.presentations.shareByEmail.replace(':hash', hash),
      { email, comment }
    ).then(() => {
      this.props.onClose();
      this.props.onSnackbarOpen(t('madeByMe.emailModal.messageSend'));
      this.setState({ email: '', comment: '' });
    }
    ).catch(() => {
      this.props.onSnackbarOpen(t('madeByMe.emailModal.error'));
    });
  }
  render() {
    const { classes, presentation, open, user, t } = this.props;

    const hash = presentation && presentation.thumbnail && presentation.thumbnail.hash;
    const imgUrl = hash ? AssetHelper.imagePath(hash, ImageVariants.TILE) : '';

    return (
      <Modal
        open={open}
        onClose={this.handleClose}
        className={classes.root}
      >
        <Grid container direction="column">
          <Typography className={classes.title}>{t('madeByMe.emailModal.sendPresentation')} {presentation.name}</Typography>
          <Grid container direction="row" alignItems="center" className={classes.mediaRow} onClick={() => shareOnFacebook(presentation.hash)}>
            <FacebookIcon className={classes.mediaIcon} />
            <Typography className={classes.shareFb}>{t('madeByMe.emailModal.shareOnFacebook')}</Typography>
          </Grid>
          <div className={classes.hr} />
          <Grid container direction="row">
            <input
              placeholder={t('madeByMe.emailModal.addressEmail')}
              className={classes.embedLink}
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />
          </Grid>
          <textarea
            placeholder={t('madeByMe.emailModal.content')}
            className={ClassNames(classes.embedLink, classes.textArea)}
            value={this.state.comment}
            onChange={e => this.setState({ comment: e.target.value })}
          />
          <Grid className={classes.emailPreview}>
            <div className={classes.presentationBackground} style={{backgroundImage: `url("${imgUrl}")`}} />
            <Typography className={classes.previewTitle}>{t('madeByMe.emailModal.invite')}</Typography>
            {user && user.displayName && user.profile.name && (
              <Typography className={classes.previewInfo}>
                {user.displayName} {t('madeByMe.emailModal.mail1')} {user.profile.name} {t('madeByMe.emailModal.mail2')}
                <strong>{presentation.name}</strong>
                {t('madeByMe.emailModal.mail3')}
              </Typography>
            )}
          </Grid>
          <Grid container direction="row" justify="center">
            <button
              disabled={!validateEmail(this.state.email)}
              className={ClassNames(classes.copyButton, !validateEmail(this.state.email) && classes.disabled)}
              onClick={() => this.handleSendInvite(presentation.hash)}
            >
              {t('madeByMe.emailModal.send')}
            </button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

EmailModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  presentation: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  onSnackbarOpen: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  translate('translations')
)(EmailModal);
