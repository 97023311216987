import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import Classnames from "classnames";
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';

import IconClose from '@material-ui/icons/Close';
import IconLabel from 'components/icons/IconLabel';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import IconFilter from '../../../components/icons/IconFilter';

import PropTypes from "prop-types";

const styles = theme => ({
  root: {
    width: 286,
    [theme.breakpoints.down('md')] : {
      width: 863,
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')] : {
      width: '90vw'
    },
  },
  button: {
    marginBottom: 25,
    backgroundColor: '#EFEFEF',
    borderRadius: 6,
    width: 286,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: '#006783',
    padding: '6px 20px',
    [theme.breakpoints.down('xl')] : {
      display: 'none',
    },
    [theme.breakpoints.down('md')] : {
      display: 'flex',
    },
  },
  title: {
    textTransform: 'uppercase',
    color: '#006783',
  },
  titleContainer: {
    padding: '6px 0 6px 10px',
    [theme.breakpoints.down('md')] : {
      display: 'none',
    },
  },
  iconLabel: {
    marginBottom: -9,
  },
  list: {
    top: 240,
    backgroundColor: '#EFEFEF',
    borderRadius: 6,
    padding: 20,
    [theme.breakpoints.down('md')] : {
      display: 'none',
      position: 'absolute',
      zIndex: 1,
      width: 286,
      maxHeight: 350,
      overflowY: 'auto',
    },
    [theme.breakpoints.down('xs')] : {
      top: 266,
    },
  },
  listOpened: {
    display: 'block'
  },
  listContent: {
    color: '#006783',
    padding: 10,
  },
  groupContainer: {
    cursor: 'pointer',
  },
  groupContainerEmpty: {
    opacity: .5,
  },
  categoryContainer: {
    // paddingLeft: 20,
  },
  group: {
    textTransform: 'uppercase',
    fontWeight: 500,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    width: 213,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  category: {
    fontWeight: 500,
    padding: '10px 0',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    width: 189,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    color: '#006783',
    height: 18,
    cursor: 'pointer',
  },
});

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      opened: false,
      groupedCategories: props.groupedCategories,
    };
    this.toggleGroup = this.toggleGroup.bind(this);
  }
  toggleGroup(i) {
    let groupedCategories = this.state.groupedCategories;
    groupedCategories[i].opened = !groupedCategories[i].opened;
    this.setState({ groupedCategories });
  }
  render() {
    const { t, classes, selectedCategories, onToggleCategorySelection, onClearCategorySelection } = this.props;
    const { open, groupedCategories } = this.state;

    return (
      <div className={classes.root}>
        <Button className={classes.button} onClick={() => this.setState({ open: !open })}>
          {t('madeByMe.page.categories')}
          {open ? <React.Fragment>{selectedCategories.length ? <IconFilter className={classes.icon} onClick={onClearCategorySelection} /> : null}</React.Fragment> : <IconLabel className={classes.iconLabel} />}
        </Button>

        <Grid className={Classnames(classes.list, open && classes.listOpened)}>
          <Grid container direction="row" alignItems="center" justify="space-between" className={classes.titleContainer}>
            <Typography className={classes.title}>
              {t('madeByMe.page.categories')}
              {selectedCategories.length ? <span> ({selectedCategories.length})</span> : null}
            </Typography>
            {selectedCategories.length ? <IconFilter className={classes.icon} onClick={onClearCategorySelection} /> : null}
          </Grid>
          {groupedCategories.map((group, i) => {
            return (
              <Grid container direction="column">
                {group.categories && group.categories.length ? (
                  <Grid container direction="row" alignItems="center" justify="space-between" className={classes.groupContainer} onClick={() => this.toggleGroup(i)}>
                    <Typography className={Classnames(classes.listContent, classes.group)}>{group.name || t('madeByMe.page.categoriesUngrouped')}</Typography>
                    <ExpandMore className={classes.icon} />
                  </Grid>
                ) : (
                  <Grid container direction="row" alignItems="center" justify="space-between" className={classes.groupContainerEmpty}>
                    <Typography className={Classnames(classes.listContent, classes.group)}>{group.name || t('madeByMe.page.categoriesUngrouped')}</Typography>
                    <ExpandMore className={classes.icon} />
                  </Grid>
                )}
                <Collapse in={group.opened} timeout="auto" unmountOnExit>
                  {group.categories.map(category => {
                    return (
                      <Grid container direction="row" alignItems="center" className={classes.categoryContainer}>
                        <Checkbox
                          onChange={() => onToggleCategorySelection(category)}
                          color="primary"
                          checked={selectedCategories.includes(category.id)}
                        />
                        <Typography className={Classnames(classes.listContent, classes.category)}>{category.name}</Typography>
                      </Grid>
                    );
                  })}
                </Collapse>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

Categories.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  groupedCategories: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired
  ).isRequired,
};

export default compose(
  withStyles(styles),
  translate('translations')
)(Categories);
