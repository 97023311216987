import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import ClassNames from 'classnames';
import axios from 'axios';
import { Api } from 'shared/api';

import ReactPaginate from 'react-paginate';
import Loader from 'components/loader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _debounce from 'lodash/debounce';

import Presentations from './components/presentations.jsx';
import Search from 'components/search';
import Filter from './components/filter.jsx';
import Categories from './components/categories';
import EmbedModal from './components/embedModal.jsx';
import EmailModal from './components/emailModal.jsx';
import StartCoViewingModal from 'components/startCoViewingModal';
import EmptyState from './components/emptyState';
import Snackbar from 'components/snackbar/snackbar';

import { actions as presentationsActions, selectors as presentationsSelectors } from 'shared/redux/presentations';
import { selectors as authSelectors } from 'shared/redux/auth';

const DefaultSort = 'publicationDateDesc';


const styles = theme => ({
  hero: {
    height: 175,
    backgroundColor: theme.palette.mediumBlue,
    [theme.breakpoints.down('xs')] : {
      height: 200,
    },
  },
  banner: {
    height: '95px',
    backgroundColor: theme.palette.darkBlue,
  },
  title: {
    fontWeight: 500,
    fontSize: 22,
    letterSpacing: '0.68px',
    color: 'white',
    [theme.breakpoints.down('xs')] : {
      alignItems: 'center',
    },
  },
  container: {
    maxWidth: 1280,
    padding: 25,
    margin: "0 auto",
    '& .cls-1': {
      fill: 'none !important',
    },
    [theme.breakpoints.down('xs')] : {
      padding: '25px 0',
    },
  },
  top: {
    position: 'absolute',
    top: 97,
    margin: '0 auto',
    maxWidth: 1280,
    padding: '0 25px',
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')] : {
      maxWidth: 863,
    },
    [theme.breakpoints.down('xs')] : {
      alignItems: 'center',
    },
  },
  grid: {
    width: '100% !important',
  },
  pagination: {
    textAlign: 'center',
    maxWidth: 400,
    margin: '0 auto',
    marginTop: 50,
    '& .previous, .next': {
      display: 'none',
    },
    '& li': {
      display: 'inline-block',
      width: 10,
      margin: 17,
      '& a': {
        color: theme.palette.gray,
        fontWeight: 300,
        transition: '.2s all',
        transform: 'translateY(5px)',
        '&:focus': {
          outline: 'none',
        }
      }
    },
    '& .selected': {
      color: theme.palette.primary.main,
      transform: 'scale(1.5)',
      fontWeight: 600,
    }
  },
  filter: {
    display: 'inline-block',
    [theme.breakpoints.down('xs')] : {
      marginTop: 5
    },
  },
  search: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')] : {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  searchInput: {
    [theme.breakpoints.down('xs')] : {
      width: '100% !important',
    },
  },
  alignCenter: {
    textAlign: 'center'
  },
  content: {
    flexDirection: 'row',
  },
  contentCat: {
    flexDirection: 'row',
    [theme.breakpoints.down('md')] : {
      flexDirection: 'column',
    },
  },
  presWithCat: {
    width: 919,
    [theme.breakpoints.down('md')] : {
      width: 'auto',
      margin: '0 auto',
    },
  }
});

class MadeByMe extends Component {
  constructor(props) {
    super(props);

    let searchParams = new URLSearchParams(window.location.search);

    this.state = {
      text: decodeURI(searchParams.get('text') || ''),
      sort: searchParams.get('sort') || DefaultSort,
      pageNum: searchParams.get('pageNum') || 1,
      pageSize: 10,
      isSnackbarOpen: false,
      isEmbedModalOpen: false,
      isEmailModalOpen: false,
      isViewingModalOpen: false,
      isViewingLoading: false,
      sharedPresentation: {},
      notificationMessage: '',
      viewingHash: '',
      viewingId: '',
      categoriesEnabled: props.user.profile.offerSettings.categoriesEnabled,
      selectedCategories: [],
    };

    this.handleFetchCategories = this.handleFetchCategories.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleEmbedModalOpen = this.handleEmbedModalOpen.bind(this);
    this.handleEmbedModalClose = this.handleEmbedModalClose.bind(this);
    this.handleEmailModalOpen = this.handleEmailModalOpen.bind(this);
    this.handleEmailModalClose = this.handleEmailModalClose.bind(this);
    this.handleViewingModalClose = this.handleViewingModalClose.bind(this);
    this.handleViewingModalOpen = this.handleViewingModalOpen.bind(this);
    this.handleViewingStart = this.handleViewingStart.bind(this);
    this.handleSnackbarOpen = this.handleSnackbarOpen.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.handleToggleCategorySelection = this.handleToggleCategorySelection.bind(this);
    this.handleClearCategorySelection = this.handleClearCategorySelection.bind(this);
    this.fetchPresentationsDebounced = _debounce(this.makeApiCall, 1000, { leading: false });
  }
  handleToggleCategorySelection(category) {
    const id = category.id;
    let selectedCategories = JSON.parse(JSON.stringify(this.state.selectedCategories));

    if (this.state.selectedCategories.indexOf(id) !== -1) {
      var filteredArray = this.state.selectedCategories.filter(e => { return e !== id });
      this.setState({ selectedCategories: filteredArray });
      this.makeApiCall(filteredArray);
    } else {
      selectedCategories.push(id);
      this.setState({ selectedCategories: selectedCategories });
      this.makeApiCall(selectedCategories);
    }
  }
  handleClearCategorySelection() {
    this.setState({
      selectedCategories: []
    });
    this.makeApiCall([]);
  }
  handleSnackbarOpen(notificationMessage) {
    this.setState({
      isSnackbarOpen: true,
      notificationMessage,
    });
  }
  handleSnackbarClose() {
    this.setState({
      isSnackbarOpen: false,
      notificationMessage: '',
    })
  }
  handleViewingModalOpen(id) {
    if (!this.state.isViewingLoading && !this.state.viewingHash) {
      const { handleCloseHeader } = this.props.navBar || {};
      this.setState({
        isViewingModalOpen: true,
        isViewingLoading: true,
      }, handleCloseHeader);
      axios.post(Api.viewings.create, {projectId: id }).then(
        response => {
          this.setState({
            isViewingLoading: false,
            viewingHash: response.data.hash,
            viewingId: response.data.id,
          });
        },
        error => this.handleSnackbarOpen(error)
      );
    }
  }
  handleViewingModalClose() {
    this.setState({
      isViewingModalOpen: false,
      viewingHash: '',
      viewingId: '',
    });
  }
  handleViewingStart() {
    const { viewingHash } = this.state;
    if (!viewingHash) return;
    this.props.history.push(`/vh/${viewingHash}`);
  }
  handleEmbedModalOpen(sharedPresentation) {
    this.setState({
      isEmbedModalOpen: true,
      sharedPresentation
    })
  }
  handleEmbedModalClose() {
    this.setState({
      isEmbedModalOpen: false,
      sharedPresentation: {}
    })
  }
  handleEmailModalOpen(sharedPresentation) {
    this.setState({
      isEmailModalOpen: true,
      sharedPresentation
    })
  }
  handleEmailModalClose() {
    this.setState({
      isEmailModalOpen: false,
      sharedPresentation: {}
    })
  }
  handlePageChange(e) {
    this.setState({
      pageNum: (e.selected + 1)
    }, () => {
      this.makeApiCall({});
    });
  }
  handleSortChange(newSort) {
    if (this.state.sort !== newSort) {
      this.setState({
        sort: newSort,
        pageNum: 1,
      }, this.makeApiCall);
    }
  }
  handleTextChange(newText) {
    if (newText !== this.state.text || this.state.sort !== DefaultSort) {
      if (newText === '') {
        this.setState({
          text: '',
          pageNum: 1,
        });
      } else {
        this.setState({
          text: newText,
          pageNum: 1,
        });
      }
      this.fetchPresentationsDebounced();
    }
  }
  componentDidMount() {
    this.makeApiCall();
    if (this.state.categoriesEnabled) {
      this.handleFetchCategories();
    }
  }
  handleFetchCategories() {
    this.props.fetchCategories();
  }
  makeApiCall(selectedCategories) {
    let { text, sort, pageNum, pageSize } = this.state,
      action,
      payload = {},
      locationParams = {},
      newLocation = '/madeByMe';

    if (pageNum > 1) {
      locationParams.pageNum = pageNum;
    }

    pageNum--;

    payload = {
      pageNum,
      pageSize,
    };

    action = this.props.fetchPresentationsProfile;
    if (selectedCategories && selectedCategories.length) {
      payload.categoryIds = selectedCategories.toString();
    }
    if (text) {
      payload.text = text;
      locationParams.text = text;
    }
    payload.sort = sort;
    if (sort && sort !== DefaultSort) {
      locationParams.sort = sort;
    }

    // Map payload to array and join with '&'
    newLocation += '?' + Object.keys(locationParams).map(k => `${k}=${locationParams[k]}`).join('&');
    // escape special characters like ' ' to '%20'
    newLocation = encodeURI(newLocation);

    this.props.history.replace(newLocation);

    action(payload);
  }

  render() {

    const { presentations, isLoading, metadata, user, categories, t, classes } = this.props;
    const {
      notificationMessage,
      pageNum,
      isEmbedModalOpen,
      isEmailModalOpen,
      sharedPresentation,
      isViewingModalOpen,
      isViewingLoading,
      viewingHash,
      viewingId,
      isSnackbarOpen,
      categoriesEnabled,
      selectedCategories
    } = this.state;

    return (
      <section className="madeByMe" style={{minHeight: 740}}>
        <div className={classes.hero}>
          <div className={classes.banner} />
        </div>
        <Grid container
              className={classes.container}
              direction="column"
              justify="center"
              alignItems="center"
        >
          <Grid
            container
            direction="row"
            className={classes.top}
          >
            <Grid className={classes.strechRow}>
              <Typography
                className={ClassNames(
                  classes.title, (presentations && presentations.length === 0) ? classes.alignCenter : null
                )}
              >
                {t('madeByMe.page.title')}
              </Typography>
            </Grid>
            {presentations && presentations.length || this.state.text ? (<Grid container direction="row" className={classes.search}>
              <Search
                classes={classes.searchInput}
                onTextChange={this.handleTextChange}
                text={this.state.text}
              />
              <Grid className={classes.filter}>
                <Filter
                  onSortChange={this.handleSortChange}
                  sort={this.state.sort}
                />
              </Grid>
            </Grid>) : null}
          </Grid>
          <Grid
            container
            justify="space-around"
            className={ClassNames(categoriesEnabled ? classes.contentCat : classes.content )}
          >
            {categoriesEnabled && categories.length ?
              <Categories
                groupedCategories={categories}
                selectedCategories={selectedCategories}
                onToggleCategorySelection={this.handleToggleCategorySelection}
                onClearCategorySelection={this.handleClearCategorySelection}
              /> : null}
            <Grid
              container
              direction="column"
              className={ClassNames(categoriesEnabled && classes.presWithCat)}
            >
              {presentations && presentations.length ? (
                <Presentations
                  className={classes.grid}
                  items={presentations}
                  categoriesEnabled={categoriesEnabled}
                  onEmbedModalOpen={this.handleEmbedModalOpen}
                  onEmailModalOpen={this.handleEmailModalOpen}
                  onViewingModalOpen={this.handleViewingModalOpen}
                />
                ) :
                <React.Fragment>
                  {this.state.text || this.state.selectedCategories.length ?
                    <Grid container alignItems="center" direction="column">{t('madeByMe.page.noSearchResults')}</Grid> :
                    <EmptyState />
                  }
                </React.Fragment>
              }
            </Grid>
          </Grid>
          {metadata.pagesCount > 1 && (
            <ReactPaginate
              forcePage={(pageNum-1)}
              pageCount={metadata.pagesCount}
              containerClassName={classes.pagination}
              onPageChange={this.handlePageChange}
            />
          )}
        </Grid>
        <Loader className={isLoading ? '' : 'isHidden'} />
        <EmbedModal
          open={isEmbedModalOpen}
          presentation={sharedPresentation}
          onSnackbarOpen={this.handleSnackbarOpen}
          onClose={this.handleEmbedModalClose}
        />
        <EmailModal
          user={user}
          open={isEmailModalOpen}
          presentation={sharedPresentation}
          onSnackbarOpen={this.handleSnackbarOpen}
          onClose={this.handleEmailModalClose}
        />
        <StartCoViewingModal
          open={isViewingModalOpen}
          loading={isViewingLoading}
          hash={viewingHash}
          id={viewingId}
          presentationId={sharedPresentation && sharedPresentation.id}
          onStart={this.handleViewingStart}
          onSnackbarOpen={this.handleSnackbarOpen}
          onClose={this.handleViewingModalClose}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isSnackbarOpen}
          message={notificationMessage}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
        />
      </section>
    );
  }
}

const {
  getCategories,
  getMetadata,
  getPresentations,
  isLoading,
} = presentationsSelectors

const {
  fetchCategories,
  fetchPresentationsProfile,
} = presentationsActions;

const {
  getCurrentUser
} = authSelectors;

const mapStateToProps = state => ({
  categories: getCategories(state),
  presentations: getPresentations(state),
  metadata: getMetadata(state),
  isLoading: isLoading(state),
  user: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchCategories,
    fetchPresentationsProfile,
  }, dispatch)
});

export default compose(
  withStyles(styles),
  translate('translations'),
  connect(
  mapStateToProps,
  mapDispatchToProps,
))(MadeByMe);
